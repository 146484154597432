export const colorsTheme = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '90deg',
  'gradient-primary': '#03624C',
  'gradient-secondary': '#051514',

  'primary-color': '#03624C',
  'secondary-color': '#646464',
  'auxiliary-color': '#03624C',

  'bg-color': '#FFFFFF',
  'bg-secondary-color': '#121212',
  'bg-auxiliary-color': '#EDEDED',

  'bg-circle-color': '#FFFFFF',
  'bg-modal-color': '#FFFFFF',

  'filter-button-background': '#03624C',
  'filter-button-color': '#FFFFFF',
  'filter-button-border': '#03624C',
  'entry-message-color': '#03624C',

  'dark-color': '#3F3F3F',

  'text-primary': '#1E1E1E',
  'text-secondary': '#03624C;',
  'text-tertiary': '#FFFFFF;',
  'text-gray': '#4F4F4F',
  
  'input-color': '#1E1E1E',
  'bg-input-color': '#FFFFFF',
  'info-color': '#B6E3D6',
  'disabled-color': '#e2e2e2',
  'card-color': '#EEEEEE',
  'modal-border-color': '#ADB5BD',
  'border-color': '#03624C',
  'bg-login-color': '#03624C',
  'bg-login-page-color': '#03624C',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#03624C',
  'echeck-bar-color2': '#03624C'
}